import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import Dashboard from "./components/Dashboard/Dashboard";
import NavMenu from "./components/Navigation/NavMenu";
import Sidebar from "./components/Navigation/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./custom.css";
import Search from "./components/Search/Search";
import Tags from "./components/Tags/Tags";
import Uploads from "./components/Uploads/Uploads";
import About from "./components/About/About";
import Register from "./components/Auth/Register";
import People from "./components/People/People";
import AIR from "./components/AIR/AIR";
import PageNotFound from "./components/404Page/PageNotFound";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import SessionManager from "./components/Auth/SessionManager";
import Admin from "./components/Admin/Admin";
import AIIR_New from "./components/AIR/AIIR_New";
import Profile from "./components/Profile/Profile";

const App = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const access_token = SessionManager.getToken();
  const isLoggedIn = access_token;

  useEffect(() => {
    if (isLoggedIn) {
      const userInfo = SessionManager.getUserFromToken();
      if (userInfo) {
        const { attachedPolicy, role } = userInfo;
        setUser(attachedPolicy);
        setUserRole(role);
      } else {
        console.error('Failed to extract user info from token');
        SessionManager.removeUserSession();
        window.location.href = "/login";
      }
    }
  }, [isLoggedIn]);

  const handleGroupChange = groupName => {
    setSelectedGroup(groupName);
  };

  const getRoutesForGroup = () => {
    // if (!selectedGroup || !user) return [];

    const group = user?.attached_policies?.find(policy => policy.group_name === selectedGroup);
    // if (!group) return [];

    // const features = group.features.map(f => f.feature_name);
    const features = group?.features?.map(f => f[1]);
    const availableRoutes = [];

    const isSuperAdmin = Array.isArray(userRole)
    ? userRole.includes("SUPERADMIN")
    : userRole === "SUPERADMIN";

    const selectedGroupFromStorage = localStorage.getItem('selectedGroup'); // temporary: for KE Demo

    if (features?.includes("search_sharedtopics") || features?.includes("search_keywords") || features?.includes("search_expertise")) availableRoutes.push({ path: "/search", component: Search });
    if (features?.includes("aiir") || features?.includes("aiir_organization") || features?.includes("aiir_faculty")) availableRoutes.push({ path: "/aiirsearch", component: AIIR_New });
    // if (features?.includes("aiir") || features?.includes("aiir_organization") || features?.includes("aiir_faculty")) {
    //   const aiirComponent = selectedGroupFromStorage === "asukedemo" ? AIIR_New : AIR;
    //   availableRoutes.push({ path: "/aiirsearch", component: aiirComponent });
    // }
    if (features?.includes("tags")) availableRoutes.push({ path: "/tags", component: Tags });
    if (features?.includes("upload")) availableRoutes.push({ path: "/upload", component: Uploads });
    if (isSuperAdmin) availableRoutes.push({ path: "/admin", component: Admin });

    return availableRoutes;
  };

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <Router>
        {isLoggedIn ? (
          <>
            <NavMenu user={user} userRole={userRole} onGroupChange={handleGroupChange} />
            <div className="layout">
              <Sidebar user={user} selectedGroup={selectedGroup} />
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                {getRoutesForGroup().map((route, index) => (
                  <Route key={index} path={route.path}>
                    {route.path === "/search" || route.path === "/aiirsearch" ? (
                      <route.component user={user} selectedGroup={selectedGroup} />
                    ) : (
                      <route.component />
                    )}
                  </Route>
                ))}
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/about" component={About} />
                <Route path="/people" component={People} />
                <Route path="/logout" component={Logout} />
                <Route path="/update-profile" component={Profile} />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </div>
          </>
        ) : (
          <>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/resetpassword" component={ResetPassword} />
              {/* Fallback Route */}
              <Route path="*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </>
          )}
      </Router>
    </div>
  );
};

export default App;
