import React, { useState } from "react";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { postData } from "../Services/AccessAPI";
import { API_MODULES } from "../Services/Settings";
import SessionManager from "./SessionManager";
import { Link } from "react-router-dom";
import "./Login.css";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log("Form submitted!");
    const loadId = toast.loading("Sending reset email...");
    const userInfo = {
      email,
    };

    try{
      const response = await postData(API_MODULES.LOGIN,"forgotPassword", userInfo);
      const result = await response.json();

      if(result?.success)
      {
        toast.update(loadId, {
          render: "Password reset email sent! Check your inbox.",
          type: "success",
          isLoading: false,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else {
        toast.update(loadId, {
          render: result?.message || "Failed to send reset email. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Error in forgot password request:", error);
      toast.update(loadId, {
        render: "An error occurred. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  return (
    <div>
      {/* <App /> */}
      <div className="forgot-password-container">
        <div className="login-box">
          <img src={asu_ker_logo} alt="Login" className="login-image" />
          <h2>Knowledge Alliance Tool</h2>
          <span className="info">Enter the email associated with your account</span>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Email</label>
            </div>
            <button type="submit">Submit</button>
          </form>
          <Link to="/login">
            <span className="login-btn">Back to login</span>
          </Link>
        </div>
        <div className="login-footer-info">
          <h1>Knowledge Exchange For Resilience</h1>
          <p>©2018 All Rights Reserved. Knowledge Exchange For Resilience!</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
